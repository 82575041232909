import React from 'react'

import { connect } from 'react-redux'
import Helmet from 'react-helmet'

import screenTypesMap from './_parts/screenTypesMap'
import Header from '../components/header'
import { LoadingBar } from '../components/loading_bar'
import LoginPanel from '../components/login'
import CreateScoringComponent from '../components/scoring'
import DuplicateScoringComponent from '../components/scoring/duplicateScoringComponent'
import Msgbox from './_parts/msgbox'
import AppVersionLabel from './_parts/appVersionLabel'
import AppVersion from '../components/app_version'
import { setGlobalEnvVariables, initSystem } from '../../va-corejs-v3'
import { loadUserFromLocalStorage } from '../../va-corejs-v3/actions/authentication'
import InterRanking from '../components/inter_ranking'
import './styles.scss'
import * as actionCreators from '../store/actions'
import SyncSnackbar from '../components/sync_snackbar'

const classNames = require('classnames')

require(`./themes/theme.${process.env.theme}.scss`) // eslint-disable-line import/no-dynamic-require

export class Layout extends React.Component {
  componentDidMount = async () => {
    const {
      updateSystemLoadStatus,
      loadLangOnlineStatusFromStorageAndUpdateTextsIntoRedux,
      updateIsOnline,
      updateDeviceSettings,
      device,
      processSyncQueue,
    } = this.props

    if (typeof window !== 'undefined') {
      window.addEventListener('online', () => {
        updateIsOnline(true)
        processSyncQueue()
      })
      window.addEventListener('offline', () => {
        updateIsOnline(false)
      })

      window.addEventListener('orientationchange', () => {
        setTimeout(() => {
          updateDeviceSettings()
        }, 100)
      })
    }

    setGlobalEnvVariables()

    await this.loadInitialDataFromLocalStorage()
    const user = await loadUserFromLocalStorage()

    if (!user) {
      await updateSystemLoadStatus(false)
      if (device.isOnline) {
        await initSystem()
      }
    }

    await loadLangOnlineStatusFromStorageAndUpdateTextsIntoRedux()

    await this.reloadeReduxStoreFromLocalStorage()
    await updateSystemLoadStatus(true)

    if (device.isOnline) {
      processSyncQueue()
    }
  }

  componentDidUpdate = async prevProps => {
    const { updateSystemLoadStatus, authentication } = this.props
    if (prevProps.authentication.authProcess.isLogged === false && authentication.authProcess.isLogged === true) {
      await updateSystemLoadStatus(false)
      await initSystem()
      await this.reloadeReduxStoreFromLocalStorage()
      await updateSystemLoadStatus(true)
    }
  }

  reloadeReduxStoreFromLocalStorage = async () => {
    const {
      updateEnv,
      updateUser,
      updateDeviceSettings,
      updateTemplates,
      updateScopes,
      updateStatus,
      updateDownloaded,
    } = this.props

    await updateUser()
    await updateEnv()
    await updateDeviceSettings()
    await updateTemplates()
    await updateScopes()
    await updateStatus()
    await updateDownloaded()
  }

  loadInitialDataFromLocalStorage = async () => {
    const {
      updateUser,
      updateTemplates,
      updateScopes,
      updateStatus,
      updateComparisonBoard,
      updatePublishingServices,
    } = this.props
    await updateUser()
    await updateTemplates()
    await updateScopes()
    await updateStatus()
    await updateComparisonBoard()
    await updatePublishingServices()
  }

  handleCheckConnection = () => {
    const { updateIsOnline, device } = this.props
    if (typeof window !== 'undefined') {
      if (window.navigator.onLine) {
        if (!device.isOnline) {
          updateIsOnline(true)
        }
      } else if (device.isOnline) {
        updateIsOnline(false)
      }
    }
  }

  render() {
    const {
      children,
      scoring,
      systemLoaded,
      isLogged,
      device,
      interRankingIsVisible,
      hideHeader,
      className,
    } = this.props

    this.handleCheckConnection()

    return (
      <div data-test="layoutComponent">
        <Helmet
          title={process.env.title}
          meta={[
            { name: 'description', content: 'Valueable v3 webApp' },
            { name: 'keywords', content: 'valueable, v3, webapp' },
          ]}
        >
          <html
            lang="en"
            className={classNames(
              {
                root: true,
                scrollDisabled: device.scrollDisabled,
              },
              className
            )}
          />

          <link rel="shortcut icon" type="image/png" href="/valueable.png" />
        </Helmet>

        <Msgbox />
        {interRankingIsVisible && <InterRanking />}

        {!systemLoaded && (
          <div
            className={classNames({
              init_loading_container: true,
            })}
          >
            <LoadingBar />
          </div>
        )}
        {systemLoaded && isLogged === false && <LoginPanel />}
        {systemLoaded && isLogged === true && (
          <div
            className={classNames({
              isSafari: device.isSafari,
              isFirefox: device.isFirefox,
              desk: device.screenType === screenTypesMap.desk,
              ipadh: device.screenType === screenTypesMap.iPadH,
              ipadv: device.screenType === screenTypesMap.iPadV,
              iphonexr: device.screenType === screenTypesMap.iPhoneXR,
              iphonese: device.screenType === screenTypesMap.iPhoneSE,
            })}
          >
            {!hideHeader && <Header siteTitle={process.env.title} onShowLoader={this.handleShowLoader} />}
            {scoring.newScoringPanelVisible && <CreateScoringComponent />}
            {scoring.duplicate.popupVisible && !scoring.duplicate.mainPopupDisabled && <DuplicateScoringComponent />}
            <div>{children}</div>
            <footer>
              <AppVersionLabel />
            </footer>
          </div>
        )}
        <SyncSnackbar />
        <AppVersion />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    appVersion: state.appVersion,
    scoring: state.scoring,
    environment: state.environment,
    device: state.device,
    texts: state.texts.values,
    authentication: state.authentication,
    systemLoaded: state.environment.systemLoaded,
    isLogged: state.authentication.authProcess.isLogged,
    directive: state.authentication.authProcess.directive,
    sync: state.sync,
    interRankingIsVisible: state.interRanking.visible,
  }
}

export default connect(mapStateToProps, actionCreators)(Layout)
