import React from 'react'
import { connect } from 'react-redux'

import _ from 'lodash'
import * as actionCreators from '../../../../store/actions'

import LastUsed from '../../../../assets/svg/template-attribute-last-used.svg'
import InUse from '../../../../assets/svg/template-attribute-in-use.svg'
import Created from '../../../../assets/svg/template-attribute-created.svg'
import { getMediaBlobUrl } from '../../../../../va-corejs-v3/api'

const classNames = require('classnames')

export class ScoringSearchHits extends React.Component {
  constructor(props) {
    super(props)
    const { template } = this.props
    this.state = {
      currTemplateId: template ?? false,
      templates: [],
    }
  }

  getMedia = (template, mediaCollection) => {
    if (!template && template.media.length > 0) {
      return null
    }

    const object = template.media.find(x => x.collection === mediaCollection)
    if (object === undefined || object === null) {
      return null
    }

    return object
  }

  getMediaBlobUrls = async mediaCollection => {
    const { scoring } = this.props
    const promises = []

    _.each(scoring.scoringSearch.hits, hit => {
      promises.push(
        new Promise((resolve, reject) => {
          const media = this.getMedia(hit, mediaCollection)
          if (!media) {
            resolve({
              templateId: hit.id,
              blobUrl: null,
            })
          }

          getMediaBlobUrl(media.file)
            .then(response => {
              resolve({
                templateId: hit.id,
                blobUrl: response,
              })
            })
            .catch(error => {
              reject(error)
            })
        })
      )
    })

    const results = await Promise.all(promises)
    return results
  }

  setTemplates = async () => {
    const { scoring } = this.props
    const flags = await this.getMediaBlobUrls('flag')
    const backgrounds = await this.getMediaBlobUrls('background')

    const templates = _.map(scoring.scoringSearch.hits, hit => {
      const flagResult = _.find(flags, flag => {
        return flag.templateId === hit.id
      })
      hit.flagBlobUrl = flagResult && flagResult.blobUrl ? flagResult.blobUrl : null

      const backgroundResult = _.find(backgrounds, background => {
        return background.templateId === hit.id
      })
      hit.backgroundBlobUrl = backgroundResult && backgroundResult.blobUrl ? backgroundResult.blobUrl : null

      return hit
    })

    this.setState({
      templates,
    })
  }

  componentDidUpdate = async prevProps => {
    const { scoring } = this.props
    const { currTemplateId } = this.state

    const currProjectMode = scoring.project_mode
    const prevProjectMode = prevProps.scoring.project_mode

    if (currProjectMode !== prevProjectMode) {
      if (currTemplateId) this.handleTemplateSelected(currTemplateId)
    }

    if (prevProps.scoring !== scoring) {
      await this.setTemplates()
    }
  }

  handleTemplateSelected = async templateId => {
    const { scoring, setTemplateOnlyInReduxForScoringDuplication, setTemplateForScoringCreationEditing } = this.props
    if (scoring.duplicate.popupVisible) {
      setTemplateOnlyInReduxForScoringDuplication(templateId)
    } else {
      setTemplateForScoringCreationEditing(scoring.newScoringId, templateId, scoring.project_mode)
    }
    this.setState({ currTemplateId: templateId })
  }

  render() {
    const { environment, texts, scoring } = this.props
    const { templates } = this.state

    function normalizeDataString(date) {
      return new Date(date).toLocaleDateString('fr-Fr')
    }

    const Templates = () => {
      const html = []
      for (let i = 0; i !== templates.length; i += 1) {
        const template = templates[i]
        const flagUrl = template.flagBlobUrl
        const backgroundImage = template.backgroundBlobUrl ? `url('${template.backgroundBlobUrl}')` : ''

        html.push(
          <div
            className={classNames({
              new_scoring_template_item: true,
              theme_box_shadow_3: template.id === scoring.selectedTemplate,
              theme_color: true,
            })}
            style={{ backgroundImage }}
            key={template.id}
            onClick={() => this.handleTemplateSelected(template.id)}
            onKeyDown={() => this.handleTemplateSelected(template.id)}
            role="button"
            tabIndex={0}
          >
            <div
              className={classNames({
                new_scoring_template_title: true,
              })}
            >
              {template.name[environment.lang]
                ? template.name[environment.lang]
                : template.name[environment.defaultLang]}
            </div>
            <div
              className={classNames({
                new_scoring_template_last_used_container: true,
                theme_svg: true,
                theme_color: true,
              })}
            >
              <LastUsed />
              <span>{texts.last_used}</span>
              <span>{normalizeDataString(template.updated_at)}</span>
            </div>
            <div className="new_scoring_template_in_use_container">
              <InUse />
              <span>{template.products_count}</span>
              <span>{texts.products_use_it}</span>
            </div>
            <div className="new_scoring_template_created_container">
              <Created />
              <span>{texts.created}</span>
              <span>{normalizeDataString(template.created_at)}</span>
            </div>
            <div className="new_scoring_template_locales_row">
              <div className="new_scoring_template_locales_container">
                {Object.keys(template.locales).map(code => (
                  <span
                    key={
                      Math.random()
                        .toString(36)
                        .substring(2) + Date.now().toString(36)
                    }
                    className="new_scoring_template_locale"
                  >
                    {code}
                  </span>
                ))}
              </div>
              <div className="new_scoring_template_flag_container">{flagUrl && <img src={flagUrl} alt="lang" />}</div>
            </div>
          </div>
        )
      }

      return html
    }

    return (
      <div className="new_scoring_search_result_container">
        <Templates />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    templates: state.templates,
    texts: state.texts.values,
    scoring: state.scoring,
  }
}

export default connect(mapStateToProps, actionCreators)(ScoringSearchHits)
